<template>
    <v-card style="margin-bottom: 2em;">
        <v-card-title>DANE Trend graphs</v-card-title>
        <v-card-text>
            <v-tabs v-model="trend_tab">
                <v-tab key="0">
                    DNSSEC Deployment Growth
                </v-tab>
                <v-tab-item  key="0">The following graph shows the growth of observed DS record sets over time (i.e. the number of signed zones).  This graph is also available as a <a href="images/totalds.svg">static image</a>.

                    <line-chart
                        :data="totalds"
                        :height="line_graph_height"> 
                    </line-chart>

                </v-tab-item>
                <v-tab key="1">
                    Signed MX and DANE records
                </v-tab>
                <v-tab-item  key="1">The following graph depicts the number of domains that have deployed DANE/SMTP.  Specifically, these zones are signed and their MX records all point to hosts that have corresonding DANE (TLSA) records.  This graph is also available as a <a href="images/domains.svg">static image</a>.
                    <line-chart
                        :data="domains"
                        :height="line_graph_height"> 
                    </line-chart>
                </v-tab-item>
                <v-tab key="2">
                    Zones Hosting DANE Mail Servers
                </v-tab>
                <v-tab-item  key="2">Many of the domains using DNSSEC and DANE for SMTP outsource or aggregate their mail servers such that many domains MX records all point to an out-of-domain mail servers (i.e. an externally hosted mail provider).  This graph depicts the number of DNS mail provider zones that have DANE records deployed for them.  This graph is also available as a <a href="images/zones.svg">static image</a>.
                    <line-chart
                        :data="zones"
                        :height="line_graph_height"> 
                    </line-chart>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
 import {fetch_one, set_url_value, get_url_value} from "../utils.js";

 var stats = {'totalds': [],
              'domains': [],
              'zones': [],
              'all': [],
              'trend_tab': 0};

 export default {
     name: 'DaneTrendGraphs',
     props: ['line_graph_height', 'bar_graph_height'],
     data: function() { return stats; },
     created: async function() {
         var data = await fetch_one("/jsons/total-ds-domains.json")
         var graph_data = {}
         data.forEach(function(row) {
             if (row['count'] && row['date']) {
                 graph_data[row['date']] = parseInt(row['count'])
             }
         })
         stats['totalds'] = [{ 'name': "Zones with a DS record",
                               'data': graph_data }];

         data = await fetch_one("/jsons/domains-and-zones.json")
         var zones_data = []
         var domains_data = []
         data.forEach(function(row) {
             zones_data.push([row['date'], row['zones']])
             domains_data.push([row['date'], row['dane']])
         })
         stats['totalds'] = [{ 'name': "Zones with a DS record",
                               'data': graph_data }];
         stats['zones'] = [{ 'name': "Zones hosting DANE/SMTP servers",
                             'data': zones_data }];
         stats['domains'] = [{ 'name': "Zones deploying DANE/SMTP",
                               'data': domains_data }];

         stats['all'] = [stats['totalds'][0],
                         stats['zones'][0],
                         stats['domains'][0],
         ]
         stats['trend_tab'] = Number(get_url_value(this, 'trend_tab', 0));
     },
     watch: {
         'trend_tab': function(val) {
             set_url_value(this, 'trend_tab', val);
         }
     }
     
 }
</script>
