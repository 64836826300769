<template>
    <!DOCTYPE html>
    <html>
        <head>
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
            <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
            <link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet">
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui">
        </head>
        <body>
            <div id="app">
                <v-app>
                    <router-view></router-view>
                    <Banner></Banner>
                    <v-main>
                        <v-container fluid>
                            <Menu :menuItems="menuItems"></Menu>
                            <v-tabs v-model="top_selector" class="mt-5 bg-red" align-tabs="center" bg-color="primary" color="red" background-color="primary" dark>
                                <v-tab href="#about">
                                    About DANE/DNSSEC Statistics
                                </v-tab>
                                <v-tab-item value="about">
                                    <About></About>
                                    <TopLevelStats :tables="tables"></TopLevelStats>
                                    <ThankYou>
                                    </ThankYou>
                                </v-tab-item>


                                <v-tab href="#dane">
                                    DNSSEC and DANE Trend Graphs
                                </v-tab>
                                <v-tab-item value="dane">
                                    <DaneTrendGraphs
                                        :line_graph_height="line_graph_height"
                                        :bar_graph_height="bar_graph_height"
                                        :trend_tab="trend_tab">
                                    </DaneTrendGraphs>
                                </v-tab-item>

                                <v-tab href="#tlds">
                                    TLD Analysis
                                </v-tab>
                                <v-tab-item value="tlds">
                                    <TLDBreakDown
                                        :line_graph_height="line_graph_height"
                                        :bar_graph_height="bar_graph_height"
                                        :tables="tables"
                                        :tables_sorted="tables_sorted"
                                        :tables_graphs="tables_graphs"
                                        :tabs="tabs"
                                        :tld_failures_by_key="tld_failures_by_key">
                                        >
                                    </TLDBreakDown>
                                </v-tab-item>


                                <v-tab href="#parameters">
                                    DNSSEC Parameter Trends
                                </v-tab>
                                <v-tab-item value="parameters">
                                    <DNSSECParameterFrequency
                                        :line_graph_height="line_graph_height"
                                        :bar_graph_height="bar_graph_height"
                                        :tables="tables"
                                        :tables_graphs="tables_graphs"
                                        :tabs="tabs"
                                        :cur_tab="cur_tab">
                                    </DNSSECParameterFrequency>
                                </v-tab-item>
                            </v-tabs>
                        </v-container>
                    </v-main>
                </v-app>
            </div>
        </body>
    </html>
</template>

<script>
 import Banner from './components/Banner.vue'
 import Menu from './components/Menu.vue'
 import About from './components/About.vue'
 import TopLevelStats from './components/TopLevelStats.vue'
 import DaneTrendGraphs from './components/DaneTrendGraphs.vue'
 import DNSSECParameterFrequency from './components/DNSSECParameterFrequency.vue'
 import TLDBreakDown from './components/TLDBreakDown.vue'
 import ThankYou from './components/ThankYou.vue'
 import Vue from 'vue'
 import Vuetify from 'vuetify'
 import {set_url_value, get_url_value} from "./utils.js";
 
 Vue.use(Vuetify)

 var vuedata = {
     current_tab: 0,
     cur_tab: 0,
     trend_tab: 0,
     'line_graph_height': "500px",
     'bar_graph_height': "400px",
     tables: {
         'KSK Algorithm': [],
         'ZSK Algorithm': [],
         'RSA KSK Sizes': [],
         'RSA ZSK Sizes': [],
         'RSA Exponents': [],
         'TLD_Failures': [],
         'TLD_Data': [],
         'top data': {},
     },
     tables_graphs: {
         'KSK Algorithm': [],
         'ZSK Algorithm': [],
         'RSA KSK Sizes': [],
         'RSA ZSK Sizes': [],
         'RSA Exponents': [],
         'TLD_Failures': [],
         'TLD_Failures_raw': [],
         'TLD_Data': [],
     },
     'tables_sorted': {
     },
     'tld_failures_by_key': {},
     tabs: [
         { 'id': 0,
           'top_selector': 'about',
           'top_selector_cache': 'about',
           'show': true,
           'tab': 'KSK Algorithm',
           'tabkey': 'ksk',
           'text': 'DNSKEY parameter frequency (1000 or more instances), by zone count:',
           'headers': [
               {text: 'KSK Algorithm', value: 'alg'},
               {text: 'Flags', value: 'flags'},
               {text: 'Protocol', value: 'proto'},
               {text: 'Domain Count', value: 'kskalgs'},
           ],
           'file': 'jsons/ksk-algs.json',
           'graph': 'images/ksk-algs.png',
           'history': 'jsons/ksk-algs-all.json',
           'history_data': [],
         },
         
         { 'id': 1,
           'show': true,
           'tab': 'ZSK Algorithm',
           'tabkey': 'zsk',
           'text': 'DNSKEY parameter frequency (1000 or more instances), by zone count:',
           'headers': [
               {text: 'ZSK Algorithm', value: 'alg'},
               {text: 'Flags', value: 'flags'},
               {text: 'Protocol', value: 'proto'},
               {text: 'Domain Count', value: 'zskalgs'},
           ],
           'file': 'jsons/zsk-algs.json',
           'graph': 'images/zsk-algs.png',
           'history': 'jsons/zsk-algs-all.json',
           'history_data': [],
         },
         { 'id': 2,
           'show': true,
           'tab': 'RSA KSK Sizes',
           'tabkey': 'rsaksk',
           'text': 'RSA key size distribution (1000 or more instances), by zone count:',
           'headers': [
               {text: 'Bits', value: 'bits'},
               {text: 'Domain Count', value: 'kskdomains'},
           ],
           'file': 'jsons/rsa-ksk-bits.json',
           'graph': 'images/rsa-ksk-bits.png',
           'history': 'jsons/rsa-ksk-bits-all.json',
           'history_data': [],
         },
         { 'id': 3,
           'show': true,
           'tab': 'RSA ZSK Sizes',
           'tabkey': 'rsazsk',
           'text': 'RSA key size distribution (1000 or more instances), by zone count:',
           'headers': [
               {text: 'Bits', value: 'bits'},
               {text: 'Domain Count', value: 'zskdomains'},
           ],
           'file': 'jsons/rsa-zsk-bits.json',
           'graph': 'images/rsa-zsk-bits.png',
           'history': 'jsons/rsa-zsk-bits-all.json',
           'history_data': [],
         },
         { 'id': 4,
           'show': true,
           'tab': 'RSA Exponents',
           'tabkey': 'rseexp',
           'text': 'Frequencies of RSA exports by the number of zones.',
           'headers': [
               {text: 'Exponent', value: 'exponent'},
               {text: 'Domain Count', value: 'domains'},
           ],
           'file': 'jsons/rsa-exponents.json',
           'graph': 'images/rsa-exponents.png',
           'history': 'jsons/rsa-exponents-all.json',
           'history_data': [],
         },
         { 'id': 5,
           'show': true,
           'tab': 'TLD_Failures',
           'tabkey': 'tldfailures',
           'headers': [
               {text: 'TLD', value: 'TLD'},
               {text: 'Number working', value: 'working'},
               {text: 'DS records', value: 'total-DS'},
               {text: 'Percent working', value: '%working'},
           ],
           'file': 'jsons/tld-fail.json'},
         { 'id': 6,
           'show': true,
           'tab': 'TLD_Data',
           'tabkey': 'tld_data',
           'headers': [
               {text: 'TLD', value: 'tld'},
               {text: 'Total Domains', value: 'total'},
               {text: 'Signed', value: 'signed'},
               {text: 'Percent Signed', value: '%signed'},
               {text: 'DANE records', value: 'DANE'},
               {text: 'Percetage with DANE', value: '%DANE'},
           ],
           'file': 'jsons/tldfrac.json'},
     ],
     'menuItems': [
         {title: 'DNSSEC-tools', path: 'https://www.dnssec-tools.org/'},
         {title: 'DNSSEC/DANE Statistics',
          path: 'https://stats.dnssec-tools.org/'},
         {title: 'Tutorials',
          path: 'https://www.dnssec-tools.org/wiki/Tutorials.html'},
         {title: 'GitHub',
          path: 'https://github.com/DNSSEC-Tools/DNSSEC-Tools'},
     ],
     sidebar: false,
 }

 var sort_tld_failures_by_key = function() {
     var results = {}
     for(var i = 0; i < vuedata.tables['TLD_Failures'].length; i++) {
         results[vuedata.tables['TLD_Failures'][i]['TLD']] = vuedata.tables['TLD_Failures'][i];
     }
     vuedata.tld_failures_by_key = results;
     // return results;
 }

 export default {
     name: 'stats',
     vuetify: new Vuetify(),
     components: {
         Banner,
         Menu,
         About,
         TopLevelStats,
         DaneTrendGraphs,
         DNSSECParameterFrequency,
         TLDBreakDown,
         ThankYou,
     },
     created() {
         vuedata.tabs.forEach(function(item) {
             fetch_data(item.file, item.tab);
         })
         fetch_data('/jsons/summary-statistics.json','top data')
     },
     data: function() { return vuedata; },
     watch: {
         tables: sort_tld_failures_by_key,
     },
     computed: {
         top_selector: {
             set(tab) {
                 if (tab) {
                     set_url_value(this, 'top', tab, true)
                     vuedata['top_selector_cache'] = tab;
                 }
             },
             get() {
                 var value = get_url_value(this, 'top');
                 if (! value) {
                     console.log("  ignoring: ",  vuedata['top_selector_cache']);
                     return vuedata['top_selector_cache'];
                 }
                 return value;
             }
         }
     },
 }

 var table_sort = function(table) {
     var app = [...table];
     app.sort(function(a, b) {
         return ('' + a['TLD']).localeCompare(b['TLD']);
     });
     // vuedata.tables_sorted = app;
     return app
 }

 var fetch_data = async function(filename, destname) {
     try {
         const response =
             await fetch(filename);
         const data = await response.json();

         vuedata['tables'][destname] = data
         console.log("loaded " + filename + " for " + destname)

         if (destname == 'TLD_Failures') {
             sort_tld_failures_by_key()
             vuedata['tables_sorted'] = table_sort(vuedata['tables']['TLD_Failures'])
         }

         var tlds = false
         data.forEach(function(row) {
             // XXX TODO: this is the wrong way
             var key = row['alg'] || row['bits'] || row['exponent'] ||
                       row["TLD"]
             var value = row['kskalgs'] || row['zskalgs'] ||
                         row['kskdomains'] || row['zskdomains'] ||
                         row['domains'] || row["%working"]
             vuedata['tables_graphs'][destname].push([key, value])

             if(row["%working"]) {
                 vuedata['tables_graphs'][destname + "_raw"].push([key,
                                                                   row["working"]])
                 tlds = true
             }
         })

         vuedata['tables_graphs'][destname].sort(function(a,b) {
             return b[1] - a[1]
         })

         if (tlds) {
             vuedata['tables_graphs'][destname + "_raw"].sort(function(a,b) {
                 return b[1] - a[1]
             })
             vuedata['tables_graphs'][destname + "_raw"] =
                 vuedata['tables_graphs'][destname + "_raw"].slice(0,30)
             vuedata['tables_graphs'][destname] =
                 vuedata['tables_graphs'][destname].slice(0,50)
         }
         

     } catch (error) {
         console.log("failed to load " + filename + " for " + destname)
     }
 };

</script>

<style>
 #app {
     font-family: Avenir, Helvetica, Arial, sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     /* text-align: center; */
     color: #2c3e50;
     margin-top: 60px;
 }
</style>
