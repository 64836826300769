export async function fetch_one(url) {
    try {
        console.log("fetching: " + url)
        var response =
            await fetch(url);
        var data = {'status': 'fail', 'message': {}};
        if (response.ok){
            data = await response.json();
        } else {
            console.log("    failed to fetch: " + response.status)
        }

        return data

    } catch (error) {
        console.log("failed to load " + url);
        console.log(error);
        console.log(response);
    }
}


// functions for storing and retrieving URL components better
export function get_url_value(parent, name, optional_default) {
    var value = parent.$route.query[name];

    if (value === undefined && optional_default) {
        value = optional_default;
    }

    // console.log("GET URL: " + name + " / value: " + value);
    // console.log(parent.$route.query);
    // console.log(parent.$route)
    return value;
}

export function set_url_value(parent, name, value, clear_all) {
    if (!parent || !('$route' in parent)) {
        console.log("unable to see route in parent");
        console.log(parent);
        return;
    }

    if (parent.$route.query[name] === value) {
        return; // no change
    }

    var newquery;
    if (clear_all) {
        // keep just the tab and date values
        newquery = {'tab': parent.$route.query['tab'],
                    'date': parent.$route.query['date'],
                    'trend': parent.$route.query['trend'],
                   };
    } else {
        newquery =  parent.$route.query;
    }

    newquery[name] = value;
    // console.log("SET URL: " + name + " = " + value + " on " + parent.$route.path);
    // console.log("new query:");
    // console.log(newquery);
    var newpath = "" + parent.$route.path;
    //parent.$router.push({'path': newpath, query: newquery})
    //var newpath = "" + parent.$route.path;
    // this is such an ugly hack to get around vue failing to update parameters

    parent.$router.push({path: "/"})
        .then(() =>
            parent.$router.push({ 'path': newpath,
                                  'query': newquery}));

    // parent.$router.push({path: "/"});
    // parent.$router.push({ 'path': newpath,
    //                       'query': newquery});

    //.catch(() => {});
}
