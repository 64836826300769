<template>
    <v-card style="margin-bottom: 2em;">
        <v-card-title>TLD Analysis</v-card-title>
        <v-card-text>
            <v-tabs v-model="tld_tab">
                <v-tab
                    >
                    Top TLDs by signed zone count
                </v-tab>
                <v-tab
                    >
                    TLD History Graphs
                </v-tab>
                <v-tab
                >
                    Top DNSSEC TLD List
                </v-tab>
                <v-tab
                >
                    TLD List
                </v-tab>
                <!--
                <v-tab
                >
                    Valid TLD DS Percentage
                </v-tab>
                -->

                <v-tab-item>
                    <p>This bar graph shows the top 30 TLDs with
                        a high number of DNSSEC functioning domains
                        (i.e. working DS records).</p>
                    <column-chart
                        :data="tables_graphs['TLD_Failures_raw']"
                        :height="bar_graph_height">
                    </column-chart>
                    <a href="images/tld-fail.png">
                    </a>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <p>Type or select TLDs to display data related to each TLD.  You can use the <i>pin</i> button to allow the display of multiple TLDs at once in the graph.</p>
                        <v-row class="mt-2">
                            <v-col cols="2">
                                <span class="ml-2"><strong>Show data for TLD:</strong></span>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    width="10%"
                                    v-model="tld"
                                    :value="tld"
                                    style="margin-top: -1em"
                                    @input="select_tld(tld)"
                                    hint="Manually type a TLD here">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-menu offset-y>
                                    <template v-slot:activator="{on: tldbutton, attrs}">
                                        <v-btn v-bind="attrs" v-on="tldbutton" color="primary" class="ml-2">
                                            {{tld}}
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in tables_sorted"
                                            :key="index"
                                            @click="select_tld(item.TLD, index)">
                                            <v-list-item-title>
                                                {{item.TLD}}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-btn
                                    @click="pin_tld(tld)"
                                    class="ml-2"
                                    color="primary"
                                    :depressed="current_is_pinned"
                                >
                                    <span v-if="current_is_pinned">Un</span>Pin {{tld}}
                                </v-btn>
                                <a :href="`/tld-graphs/${tld}.png`">
                                    <v-btn
                                        class="ml-2"
                                        color="primary"
                                    >
                                        View static {{tld}} graph 
                                    </v-btn>
                                </a>
                            </v-col>
                        </v-row>

                            <v-simple-table dense class="width: 20%;" v-if="tld_failures_by_key[tld]">
                                <tbody>
                                    <tr><th>Total DS records</th><td> {{tld_failures_by_key[tld]['total-DS']}}</td></tr>
                                    <tr><th>Working DS records</th><td> {{tld_failures_by_key[tld]['working']}}</td></tr>
                                    <tr><th>Percentage Working</th><td> {{tld_failures_by_key[tld]['%working']}}</td></tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-card-title>Signed Subdomain Count</v-card-title>
                        <v-card-text>
                            <line-chart
                                :data="graph_data"
                                :height="line_graph_height">
                            </line-chart>
                            <em>(click on a label to hide it on the graph)</em>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>

                    <p class="mt-5">This table shows the number of delegations
                        within each TLD containing
                        DS records (thus indicating the number of signed subzones).
                        The table also shows the number of
                        succesful DNSKEY lookups for domains under the given TLD,
                        meaning the number of zones that
                        the DNSKEYs within the subzone could be both retrieved and verified
                        as authentic using DNSSEC.
                    </p>
                    <p>
                        <em>Note:</em> failure reasons
                        include both failures in DNS itself for the subzone
                        (e.g. no reachable name servers)
                        or because DNSSEC validation of the results failed.
                    </p>

                    <p><em>Note:</em>
                        Only TLDs are considered where the number of
                        securely delegated subzones is greater than 999</p>

                    <v-data-table
                        hide-default-footer disable-pagination
                        :items=tables.TLD_Failures
                        :headers=tabs[5].headers>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>

                    <p>This table shows the complete set of counts for
                    all TLDs we know a number of details about, DNSSEC
                        related or otherwise.
                    </p>
                    <p>  <b>Warning:</b> Note that many TLDs have
                        incomplete information, or the counts are from
                        the best information available but may be
                        incomplete or dated.
                    </p>

                    <v-data-table
                        hide-default-footer disable-pagination
                        :items=tables.TLD_Data
                        :headers=tabs[6].headers>
                    </v-data-table>
                </v-tab-item>
                <!-- 
                <v-tab-item>
                    <p>This shows the percentage of signed delegations
                    that are valid within the top 50 TLDs, ranked by
                    their percentage.</p>
                    <column-chart
                        :data="tables_graphs['TLD_Failures']"
                        :height="bar_graph_height">
                    </column-chart>
                </v-tab-item>
                -->
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
 import {fetch_one, set_url_value, get_url_value} from "../utils.js";
 
 var TLD={'tld': 'com',
          'index': 0,
          'one': 42,
          'tld_tab': 1,
          'tld_dnssec_data': {'com': []},
          'graph_data': [],
          'current_is_pinned': false,
          'pinned_data': {}};

 var select_tld = function(tld, index) {
     TLD['tld']=tld;
     TLD['index']=index;
     TLD['current_is_pinned'] = TLD['pinned_data'][tld]
     set_url_value(this, "tld", tld)
 }

 var update_tld_graph = async function(tld) {
     if (!(tld in TLD['tld_dnssec_data']) || TLD['tld_dnssec_data'][tld].length == 0) {
         var data = await fetch_one("/jsons/tlds/" + tld + ".json")
         var newdata = []
         data.forEach(function(row) {
             var d = new Date(0)
             d.setUTCSeconds(parseInt(row['timestamp']))
             newdata.push([d, parseInt(row['count'])])
         })
         TLD['tld_dnssec_data'][tld] = newdata
     }
     TLD['graph_data'] = [{
         name: tld,
         'data': TLD['tld_dnssec_data'][tld]
     }]
     Object.keys(TLD['pinned_data']).forEach(function(tld) {
         TLD['graph_data'].push({
             name: tld,
             'data': TLD['tld_dnssec_data'][tld]
         })
     })
 }
 
var pin_tld = function(tld, dont_update_url = false) {
     if (tld in TLD['pinned_data']) {
         delete TLD['pinned_data'][tld];
     } else {
         TLD['pinned_data'][tld] = true;
     }
     TLD['current_is_pinned'] = TLD['pinned_data'][tld]
     if  (!dont_update_url) {
         set_url_value(this, "pinned", Object.keys(TLD['pinned_data']));
     }
 }

 export default {
     name: 'TLDBreakDown',
     props: ['tables', 'tables_sorted', 'tables_graphs', 'tabs',
             'tld_failures_by_key', 'line_graph_height', 'bar_graph_height'],
     data: function() { return TLD; },
     methods: {
         'select_tld': select_tld,
         'pin_tld': pin_tld,
     },
     created() {
         var pinned = get_url_value(this, "pinned")
         if (pinned) {
             pinned.forEach(function(item) {
                 update_tld_graph(item);
                 pin_tld(item, true);
             });
         }
         TLD['tld'] = get_url_value(this, "tld", "com")
         update_tld_graph(TLD['tld']);

         var tab_val = get_url_value(this, 'tld_tab', -1);
         tab_val = Number(tab_val);
         TLD['tld_tab'] = tab_val;
     },
     watch: {
         'tld': async function() {
             update_tld_graph(TLD['tld']);
         },
         'tld_tab': function(newval) {
             set_url_value(this, 'tld_tab', newval)
         }
     }
 }
</script>
