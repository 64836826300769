<template>
    <v-row fluid><v-col cols=12>
        <v-card>
            <v-card-title>About</v-card-title>
            <v-card-text>

        <p>This page documents the results of scanning the
        DNS to measure the growth of <em>DNSSEC</em> and
        <em>DANE</em> over time.  The scanning is
        performed by Viktor Dukhovni (Google), with
        support from USC/ISI for scanning infrastructure.
        <a href="https://www.isi.edu/~hardaker/">Wes
            Hardaker (USC/ISI)</a>
        converts the resulting data into the 
        <a href="/#/top=dane">DNSSEC/DANE Trend Graphs</a>,
        <a href="/#/top=tlds">TLD information</a>, and
        <a href="/#/top=dnssec">DNSSEC parameter trends</a>
        charts and tables as part of the
        <a href="https://dnssec-tools.org/">DNSSEC-Tools</a>
        project.  For further information, please see
        the <a href="about.html">project description 
            page</a>.</p>

        <p>We believe that the survey is for the
            benefit of both you and the Internet.
            We scan the Internet for DNSSEC and DANE related growth
            and problems,  letting operators (like you)
            know when we detect problems. To achieve this goal, we
            ask that SMTP connections from the
            survey not be blocked.</p>

        <p><b>Scanning OPT-OUT information:</b> we scan both
            DNS records and DANE/TLS/SMTP servers from
            the <em>dnssec-stats.ant.isi.edu</em> host using both IPv4
            and IPv6.  If you wish to be added to our Out-Out list for
            your domain name(s) or IP address(es), please contact
            ant-dnssec-operators@ant.isi.edu and include the IP
            addresses and/or names that you wish to be added
            to the opt-out list.</p>
            </v-card-text>
        </v-card>
    </v-col>
    </v-row>

</template>
<script>
 export default {
     name: 'About'
 }
</script>

<style>
 p {
     margin-bottom: .5em;
     font-size: 1.2em;
 }
</style>
