<template>
    <div>
        <h3 class="dttitle">DNSSEC and DANE Deployment Statistics</h3>
    <center>
        <v-btn
            v-for="item in menuItems"
            :key="item.title"
            :to="item.path"
            @click="jumpto(item.path)"
            color="primary"
            class="mx-2">
            <a :href="item.path" style="text-decoration: none;">
                <v-btn text class="white--text">{{ item.title }}</v-btn>
            </a>
        </v-btn>
    </center>
    </div>
</template>

<script>
 export default {
     name: 'Menu',
     props: ['menuItems'],
     methods: {
         jumpto: function(where) {
             console.log("jumpto: " + where);
             window.location.replace(where);
         }
     }
 }
</script>

<style>
 .dttitle {
     text-align: center;
     font-size: 1.8em;
     margin-bottom: 1em;
 }
</style>
