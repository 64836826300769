<template>
    <v-card style="margin-bottom: 2em;">
        <v-card-title>DNSSEC parameter frequency analysis</v-card-title>
        <v-card-text>
            <p>The following tabs summarize the deployment size and
                states of various DNSSEC parameters.</p>

            <v-tabs v-model="dnssec_param_tab">
                <v-tab :key="tab.id"
                       v-for="tab in tabs.slice(0,5)">
                    <template v-if="tab.id !== 5">
                        {{tab.tab}}
                    </template>
                </v-tab>
                <v-tab-item v-for="tab in tabs.slice(0,5)"
                            :key="tab.id"
                            >
                    <p v-if="tab.text" class="mt-5">
                        {{tab.text}}
                    </p>
                    <h3>Historical trend</h3>
                    <line-chart :data="tab.history_data" v-if="tab.history_data">
                    </line-chart>
                    <h3>Today's Values</h3>
                    <v-row>
                        <v-col cols="6">
                            <v-data-table hide-default-footer
                                          disable-pagination
                                          dense
                                          :items='tables[tab.tab]'
                                          :headers=tab.headers>
                            </v-data-table>
                        </v-col>
                        <v-col v-if="tab.file">
                            <column-chart
                                :data="tables_graphs[tab.tab]"
                                :height="bar_graph_height">
                            </column-chart>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
 import Vue from 'vue';
 import Chartkick from 'vue-chartkick';
 import Highcharts from 'highcharts';
 import {set_url_value, get_url_value, fetch_one} from "../utils.js";
 //import {set_url_value} from "../utils.js";

 Vue.use(Chartkick.use(Highcharts))

 var data = {
     'dnssec_param_tab': 0,
     'textnames':
     {
         'ksk': {
             0: "DELETE",
             1: "RSAMD5",
             2: "DH",
             3: "DSA",
             4: "",
             5: "RSASHA1",
             6: "DSA-NSEC3-SHA1",
             7: "RSASHA1-NSEC3-SHA1",
             8: "RSASHA256",
             9: "",
             10: "RSASHA512",
             11: "",
             12: "ECC-GOST",
             13: "ECDSAP256SHA256",
             14: "ECDSAP384SHA384",
             15: "ED25519",
             16: "ED448",
             17:  "",
             123: "",
             252: "INDIRECT",
             253: "PRIVATEDNS",
             254: "PRIVATEOID",
             255: ""
         },
     }
 };

 // duplicates
 data['textnames']['zsk'] = data['textnames']['ksk'];

 export default {
     name: 'DNSSECParameterFrequency',
     props: ['tables', 'tabs', 'tables_graphs',
             'line_graph_height', 'bar_graph_height'],
     data: function() { return data; },
     created: async function() {
         // duplicate these
         data['dnssec_param_tab'] = Number(get_url_value(this, 'dnssec_param_tab', 0));

         this.tabs.forEach(async function(tab) {
             if (tab['history']) {
                 var rows = [];
                 var history_data = await fetch_one(tab['history']);
                 Object.keys(history_data).forEach(function(key) {
                     var dataname = key;
                     var value = history_data[key];
                     
                     if (tab['tabkey'] &&
                         data['textnames'][tab['tabkey']] &&
                         data['textnames'][tab['tabkey']][key]) {
                         dataname = dataname + " (" +
                                    data['textnames'][tab['tabkey']][key] +
                                    ")"
                     }
                     rows.push({name: dataname, data: value});
                 });
                 tab['history_data'] = rows;
             }
         });
     },
     watch: {
         'dnssec_param_tab': function(val) {
             set_url_value(this, 'dnssec_param_tab', val);
         },
         'tabs': function(val) {
             console.log("TABS CHANGED: ", val)
         }
     },
 }
</script>
