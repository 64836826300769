import Vue from 'vue'
import App from './App.vue'
import 'vuetify/dist/vuetify.min.css'
import Router from 'vue-router'

Vue.config.productionTip = false
Vue.use(Router)

const routes = [{path: '*', name: 'stats', component: App}];

const router = new Router(routes);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
