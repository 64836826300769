<template>
    <v-row>
    <v-col cols="6">
    <v-card style="margin-bottom: 2em;">
        <v-card-title>Summary Statistics</v-card-title>
        <v-card-text>
            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                        <tr><th>Last updated:</th>
                            <td>{{tables['top data']['lastupdated']}}</td></tr>
                        <tr><th>Total number of DS Resource Record sets:</th>
                            <td>{{tables['top data'].dsdomains}}</td></tr>
                        <tr><th>Validatable DNSKEY record sets:</th>
                            <td>{{tables['top data']['workingkeys']}}</td></tr>
                        <tr><th>Total DANE protected SMTP domains:</th>
                            <td>{{tables['top data']['totaldane']}}</td></tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
    </v-col>
    <v-col cols="6">
        <v-card>
            <v-card-title>Explore the per-zone dataset</v-card-title>
            <v-card-text>
                <p>For administrators that want greater details
                    about the results of our recent scan of your
                    DNSSEC signed domain, please see our
                    <a href="http://stats.dnssec-tools.org/explore">explore</a> page.  You
                    can see the results, along with any DNSSEC
                    and DANE issues, our scanner found in the
                    last 24 hours.
                </p>
                <center>
                    <v-btn color="primary" style="text-decoration: none;">
                        <a href="http://stats.dnssec-tools.org/explore"  style="text-decoration: none;" class="white--text" >
                            Explore The Scanning Results
                        </a>
                    </v-btn>
                </center>
            </v-card-text>
        </v-card>
    </v-col>
    </v-row>
</template>

<script>
 export default {
     name: 'TopLevelStats',
     props: ['tables']
 }
</script>

<style>
 .dtbanner {
     text-align: center;
     font-size: 1.5em;
     margin-bottom: 1em;
 }
 th {
     text-align: left;
     text-size: 1.5em;
 }
 td {
     text-size: 1.5em;
 }
 .v-data-table tbody th, .v-data-table tbody td {
     font-size: 1.2em !important;
 }
</style>

